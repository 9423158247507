<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.4352 4.84963L8 13.2853V16.0001H10.7159L19.1505 7.56493L16.4352 4.84963Z" fill="#1F284D" />
    <path
      d="M20.5646 6.15067L21.293 5.42229C21.6835 5.03177 21.6835 4.39865 21.293 4.00813L19.992 2.70699C19.6014 2.31643 18.9682 2.31642 18.5777 2.70697L17.8493 3.43537L20.5646 6.15067Z"
      fill="#1F284D"
    />
    <path
      d="M12 2.99988H5C3.89543 2.99988 3 3.89532 3 4.99988V18.9999C3 20.1045 3.89543 20.9999 5 20.9999H19C20.1046 20.9999 21 20.1045 21 18.9999V10.9999H19V18.9999H5L5 4.99988H12V2.99988Z"
      fill="#1F284D"
    />
  </svg>
</template>
<script>
export default {
  props: {
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
