<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 2H10V4H3C2.44772 4 2 4.44772 2 5V19C2 19.5523 2.44772 20 3 20H10V22H12V20V19V5V4V2ZM5.18357 15H3.70062L5.9602 8.45455H7.74358L9.99998 15H8.51702L8.03242 13.5075H5.66912L5.18357 15ZM6.87746 9.95028L7.68168 12.4272H6.02054L6.82633 9.95028H6.87746ZM14 20H21C21.5523 20 22 19.5523 22 19V5C22 4.44772 21.5523 4 21 4H14V6H20V18H14V20ZM14 15V8.45455H16.5711C17.0526 8.45455 17.4542 8.52592 17.776 8.66868C18.0977 8.81143 18.3395 9.00959 18.5015 9.26314C18.6634 9.51456 18.7444 9.80433 18.7444 10.1325C18.7444 10.3881 18.6932 10.6129 18.591 10.8068C18.4887 10.9986 18.3481 11.1562 18.1691 11.2798C17.9922 11.4013 17.7898 11.4876 17.5618 11.5387V11.6026C17.8111 11.6133 18.0444 11.6836 18.2618 11.8136C18.4812 11.9435 18.6591 12.1257 18.7955 12.3601C18.9319 12.5923 19 12.8693 19 13.1911C19 13.5384 18.9138 13.8484 18.7412 14.1211C18.5707 14.3917 18.3182 14.6058 17.9837 14.7635C17.6492 14.9212 17.2369 15 16.7468 15H14ZM15.2203 13.8686H16.4624C16.8481 13.8686 17.1293 13.7951 17.3062 13.6481C17.483 13.4989 17.5714 13.3008 17.5714 13.0536C17.5714 12.8725 17.5277 12.7127 17.4404 12.5742C17.353 12.4357 17.2284 12.3271 17.0665 12.2482C16.9066 12.1694 16.716 12.13 16.4944 12.13H15.2203V13.8686ZM15.2203 11.1935H16.3601C16.5498 11.1935 16.7181 11.1605 16.8651 11.0945C17.0142 11.0263 17.1314 10.9304 17.2167 10.8068C17.304 10.6832 17.3477 10.5352 17.3477 10.3626C17.3477 10.1261 17.2635 9.93537 17.0952 9.79048C16.929 9.6456 16.6925 9.57315 16.3857 9.57315H15.2203V11.1935Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#1F284D' },
    size: { type: Number, required: false, default: 16 },
  },
};
</script>
