<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.7377 2.30555C12.3303 1.89815 11.6697 1.89815 11.2623 2.30555L2.30555 11.2623C1.89815 11.6697 1.89815 12.3303 2.30555 12.7377L11.2623 21.6945C11.6697 22.1019 12.3303 22.1019 12.7377 21.6945L21.6945 12.7377C22.1019 12.3303 22.1019 11.6697 21.6945 11.2623L12.7377 2.30555ZM10.5998 7.99656C10.5458 7.18658 11.1882 6.49988 12 6.49988C12.8118 6.49988 13.4542 7.18658 13.4002 7.99656L13.0665 13.0021C13.0291 13.5636 12.5627 13.9999 12 13.9999C11.4373 13.9999 10.9709 13.5636 10.9335 13.0021L10.5998 7.99656ZM11 15.9999C11 15.4476 11.4477 14.9999 12 14.9999C12.5523 14.9999 13 15.4476 13 15.9999C13 16.5522 12.5523 16.9999 12 16.9999C11.4477 16.9999 11 16.5522 11 15.9999Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: { type: String, required: false, default: '#E52044' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
