<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 5H19V14H10V5ZM8 5C8 3.89543 8.89543 3 10 3H19C20.1046 3 21 3.89543 21 5V14C21 15.1046 20.1046 16 19 16H10C8.89543 16 8 15.1046 8 14V5ZM5 7.99999H7V9.99999H5L5 19H14V17H16V19C16 20.1046 15.1046 21 14 21H5C3.89543 21 3 20.1046 3 19V9.99999C3 8.89542 3.89543 7.99999 5 7.99999Z"
      fill="#1F284D"
    />
  </svg>
</template>
<script>
export default {
  props: {
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
